import React from 'react';
import { Box } from 'rebass/styled-components';
import Layout from '../components/Layout';
import { PageHeading, NewsGrid } from '../components';
import { useNewsesQuery } from '../queries/useNewsesQuery';
import { useBeautyQueries } from '../queries/useBeautyQueries';
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const beautyshare = (pageContext: any) => {
  const allWpBeautyShare = pageContext.data.allWpBeautyShare;
  const beauty = useBeautyQueries('');
  const newses = beauty.map( ({id, title, thumbnail}) => {
    return {
      id: id,
      title: title,
      thumbnail: thumbnail,
      description: '',
      date: '',
      url: id
    }
  });
  return (
    <Layout>
      {allWpBeautyShare.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}
      <Box variant="container">
        <PageHeading subTitle="Beauty Trends">美麗分享</PageHeading>
        <NewsGrid newses={newses} />
      </Box>
    </Layout>
  );
};

export default beautyshare;

export const pageQuery = graphql`
  query GET_PAGE_BEAUTY {
    allWpBeautyShare {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;